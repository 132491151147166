<div class="tab-dtls pd-tb-60">
  <div class="container">
    <mat-card class="tab-content" id="tab-content1">
      <div class="tab-pane tab-pane-profile active">
        <div class="row" style="text-align: center;
    justify-content: center;">
          
          <div class="col-md-6 col-sm-6 col-xs-12">
            <div class="view-tab">
              <div class="edit" >
                <h4>{{language.DELETE_YOUR_ACCOUNT}}</h4>
                
                <div class="form-group">
                  <input type="text" autocomplete="off" placeholder="{{language.EMAIL}}"  [(ngModel)]="loginform.email" required
                    #login_email>
                </div>
                <div class="form-group">
                  <input type="password"  autocomplete="off" placeholder="{{language.PASSWORD}}"  [(ngModel)]="loginform.password" required
                    #login_password>
                </div>
                <div class="form-group">
                  <button class="cmn-button" (click)="DeleteUser()">{{language.SUBMIT}}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-card>
  </div>
</div>