import { Component, ComponentFactory, ComponentFactoryResolver, HostListener } from '@angular/core';
import { ElementRef, Inject, ViewChild } from '@angular/core';
import { NgZone, OnInit, ViewContainerRef } from '@angular/core';
import { PLATFORM_ID } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { isPlatformBrowser } from '@angular/common';
import { Location, DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

import { CommonService } from './../common.service';
import { ApiService } from './../api.service';
import { DataSharingService } from './../data-share.service';
import { Globals } from '../globals';

// import {} from 'googlemaps'
/// <reference types="@types/googlemaps" />

@Component({
  selector: 'app-profile',
  templateUrl: './deleteaccount.component.html',
  styleUrls: ['./deleteaccount.component.css']
})
export class DeleteAccountComponent implements OnInit {
  public globals: Globals;
  public language: any;

  public loginform: any = {
    email: '',
    password: ''
  };

  @ViewChild('login_email') login_email!: ElementRef;
  @ViewChild('login_password') login_password!: ElementRef;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: Object,
    public snackBar: MatSnackBar,
    private spinner: NgxSpinnerService,
    public location: Location,
    public router: Router,
    private comservice: CommonService,
    private apiservice: ApiService,
    private dataSharingService: DataSharingService,
    private ngZone: NgZone,
    globals: Globals
  ) {
    this.globals = globals;
    this.language = this.globals.LANGUAGE[this.globals?.LANG_ID as keyof typeof this.globals.LANGUAGE];
  }

  ngOnInit() {
   
  }

  DeleteUser() {
        
    if (this.loginform.email === '') {
      this.snackBar.open(this.language.ENTER_EMAIL_ADDRESS, this.language.TOAST_OK, {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
      if (isPlatformBrowser(this.platformId)) {
        this.login_email.nativeElement.focus();
      }
      return false;
    }
    let atpos;
    atpos = this.loginform.email.indexOf('@');
    let dotpos;
    dotpos = this.loginform.email.lastIndexOf('.');
    if (atpos < 1 || dotpos < atpos + 2 || dotpos + 2 >= this.loginform.email.length) {
      this.snackBar.open(this.language.ENTER_VALID_EMAIL_ADDRESS, this.language.TOAST_OK, {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
      if (isPlatformBrowser(this.platformId)) {
        this.login_email.nativeElement.focus();
      }
      return false;
    }

    if (this.loginform.password === '') {
      this.snackBar.open(this.language.ENTER_PASSWORD, this.language.TOAST_OK, {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
      if (isPlatformBrowser(this.platformId)) {
        this.login_password.nativeElement.focus();
      }
      return false;
    }
    
    this.apiservice.DeleteUserAccount(this.loginform).subscribe((response: any) => {
     
      this.loginform = {
        email: '',
        password: ''
      }
      if(response.status == true){
        this.snackBar.open(this.language.ACCOUNT_DELETE_SUCCESSFULLY, this.language.TOAST_OK, {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'top'
        });
      }else{
        this.snackBar.open(response.message, this.language.TOAST_OK, {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'top'
        });
      }
      


    });
  }
 
  
}
